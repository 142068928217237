
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"0bd0217e96d5479eabb90f70ca13028bde48573e":"getServerTime"} */ export var getServerTime = createServerReference("0bd0217e96d5479eabb90f70ca13028bde48573e");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

