import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'

interface RcTableResultsProps {
  total?: number
  showing?: number
  align?: 'flex-start' | 'center' | 'flex-end'
  tooltip?: React.ReactNode
  onTooltipOpen?: () => any
  sx?: SxProps<Theme>
}

export const RcTableResults: React.FC<RcTableResultsProps> = ({
  total,
  showing,
  align = 'center',
  tooltip,
  onTooltipOpen,
  sx
}) => {
  return (
    <Stack
      direction='row'
      spacing={2}
      justifyContent={align}
      sx={sx}
      alignContent='center'
    >
      <Typography variant='subtitle1' color='text.secondary'>
        {/* negative value indicates loading. space instead of return null to prevent visual jank. */}
        {total === -1 ? (
          <>&nbsp;</>
        ) : showing ? (
          <RcTrans
            i18nKey='shared.showing-total-display'
            tOptions={{ showing, total }}
          />
        ) : (
          <RcTrans i18nKey='shared.total-display' tOptions={{ total }} />
        )}
      </Typography>
      {tooltip ? (
        <Tooltip enterDelay={0} arrow placement='top' title={tooltip}>
          <span onMouseEnter={onTooltipOpen}>
            <RcIcon icon={['fal', 'triangle-exclamation']} color='warning' />
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  )
}
