import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import { useLocalStorage } from './useLocalStorage'
import { useRcTranslation } from './useRcTranslation'
import { getServerTime } from './getTime'
import { Timezone } from '@/components/pages/Settings/components/TimezonePreference'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const useTime = () => {
  const [tz, setTzInternal] = useLocalStorage({
    key: 'default_event_tz',
    defaultValue: moment.tz.guess() || Timezone.Pacific
  })
  const { feat24hTime } = useFeatures()
  const { t } = useRcTranslation()

  const timeDisplayFormat = useMemo(
    () => (feat24hTime ? 'HH:mm' : 'h:mm A'),
    [feat24hTime]
  )
  const timeTzDisplayFormat = useMemo(
    () => (feat24hTime ? 'HH:mm z' : 'h:mm A z'),
    [feat24hTime]
  )

  const datetimeDisplayFormat = useMemo(
    () => `MMM DD, YYYY ${timeTzDisplayFormat}`,
    [timeTzDisplayFormat]
  )

  const displayDate = useCallback(
    (date?: Date | moment.Moment) =>
      date ? moment.tz(date, tz!).toDate().toDateString() : '',
    [tz]
  )

  const displayDateUTC = useCallback(
    (date?: Date | moment.Moment) =>
      date ? moment.tz(date, 'UTC').format('MMM DD, YYYY') : '',
    []
  )

  const displayTime = useCallback(
    (date?: Date | moment.Moment, skipAmPm?: boolean) =>
      date
        ? moment
            .tz(date, tz!)
            .format(timeDisplayFormat.replace(skipAmPm ? ' A' : '', ''))
        : '',
    [timeDisplayFormat, tz]
  )

  const displayRelativeTime = useCallback(
    (date?: Date | moment.Moment) =>
      date
        ? moment.tz(date, tz!).calendar({
            sameDay: `${timeDisplayFormat}`,
            nextDay: `[${t('shared.tomorrow-at')}] ${timeDisplayFormat}`,
            lastDay: `[${t('shared.yesterday-at')}] ${timeDisplayFormat}`,
            lastWeek: `dddd`,
            nextWeek: 'dddd',
            sameElse: `L`
          })
        : '',
    [timeDisplayFormat, tz, t]
  )

  const { data: timeOffset } = useSWRImmutable(
    'currentTime',
    async () => {
      try {
        const prev = await getServerTime()
        const diff = new Date().getTime() - prev
        return diff
      } catch (error) {
        console.log('unable to fetch external time', error)
        return 0
      }
    },
    {
      revalidateOnMount: false,
      revalidateIfStale: false,
      revalidateOnFocus: false
    }
  )

  const getNow = useCallback(
    () => moment().subtract(timeOffset, 'ms'),
    [timeOffset]
  )

  const displaySmallRelativeTime = useCallback(
    (date?: Date) => {
      const now = getNow()

      if (!date) return ''

      const diff = now.diff(moment(date))
      const duration = moment.duration(diff)
      if (duration.asDays() > 14) return ''

      if (duration.asMinutes() < 1) {
        return t('shared.now') as string
      } else if (duration.asHours() < 1) {
        return `${Math.round(duration.asMinutes())}m`
      } else if (duration.asHours() < 24) {
        return `${Math.round(duration.asHours())}h`
      } else {
        return `${Math.round(duration.asDays())}d`
      }
    },
    [t, getNow]
  )

  const displayDateTime = useCallback(
    (date?: Date | moment.Moment) =>
      date ? moment.tz(date, tz!).format(datetimeDisplayFormat) : '',
    [datetimeDisplayFormat, tz]
  )

  const displayTimeSpan = useCallback(
    (start: Date | moment.Moment, end?: Date | moment.Moment) => {
      const formattedStart = moment
        .tz(start, tz!)
        .format(feat24hTime ? 'HHmm' : 'ha')
      const formattedEnd = moment
        .tz(end, tz!)
        .format(feat24hTime ? 'HHmm' : 'ha')

      return moment.tz(start, tz!).calendar({
        sameDay: `[${formattedStart} - ${formattedEnd}]`,
        nextDay: `[${t('shared.tomorrow')}]`,
        lastDay: `[${t('shared.yesterday')}]`,
        lastWeek: `[${t('shared.last')}] dddd`,
        nextWeek: 'dddd',
        sameElse: `L`
      })
    },
    [feat24hTime, tz, t]
  )

  const displayDateSpan = useCallback(
    (start: Date | moment.Moment, end?: Date | moment.Moment) => {
      const formattedStart = moment.tz(start, tz!).format(datetimeDisplayFormat)
      const formattedEnd = moment.tz(end, tz!).format(datetimeDisplayFormat)

      return (
        formattedStart +
        (formattedEnd && formattedStart !== formattedEnd
          ? ` - ${formattedEnd}`
          : '')
      )
    },
    [datetimeDisplayFormat, tz]
  )

  const displayEventDateTime = useCallback(
    (start: Date | moment.Moment, end?: Date | moment.Moment) => {
      // for events more than a day long display like Jan 16 - Jan 23
      if (end && !moment(start).isSame(end, 'day')) {
        return `${moment.tz(start, tz!).format('MMM D')} - ${moment
          .tz(end, tz!)
          .format('MMM D')}`
      }

      // for shorter events display like Jan 16 @ 2pm - 4pm
      const formattedStart = moment.tz(start, tz!).format('MMM DD @ h:mma')
      return (
        formattedStart +
        (end ? ` - ${moment.tz(end, tz!).format('h:mma')}` : '')
      )
    },
    [tz]
  )

  const setTz = (val: string) => {
    setTzInternal(val)
  }

  useEffect(() => {
    moment.tz.setDefault(tz)
  }, [tz])

  return {
    displayDate,
    displayDateUTC,
    displayTime,
    displayRelativeTime,
    displaySmallRelativeTime,
    displayDateTime,
    displayTimeSpan,
    displayDateSpan,
    displayEventDateTime,
    tz,
    setTz,
    feat24hTime,
    timeDisplayFormat: timeTzDisplayFormat,
    getNow
  }
}
