import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { CompetitionDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionDocument'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { DiscoverCard } from './DiscoverCard'
import { CHIPINATOR_DELIMITER } from '@/components/molecules/text/Chipinator'
import { ScheduleTypography } from '@/components/molecules/text/ScheduleTypography'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useTime } from '@/core/hooks/useTime'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'

export const CompetitionDocumentCard = ({
  googleTag,
  resource
}: {
  googleTag?: string
  resource: CompetitionDocument
}) => {
  const { navTo } = useNavigation()
  const { getNow } = useTime()
  const {
    featCompetitionDiscoveryAvatars,
    featCompetitionDiscoveryBanners,
    imgCommunityBanner
  } = useFeatures()
  const now = getNow()?.toDate()

  const defaultBannerImageSrc = imgCommunityBanner as string

  const activeEvent = resource.events?.find(
    it => it.startDate! < now && now < it.endDate!
  )
  const chipinatorValues =
    resource?.disambiguatingDescription?.split(CHIPINATOR_DELIMITER) || []
  const stateIcon =
    resource?.state === CompetitionState.INPROGRESS ? (
      <RcIcon icon={['fal', 'calendar']} size='lg' />
    ) : (
      <RcIcon icon={['fal', 'moon']} size='lg' />
    )
  return (
    <DiscoverCard
      avatarImage={
        featCompetitionDiscoveryAvatars
          ? resource.image || resource.game?.icon
          : undefined
      }
      headerImage={
        featCompetitionDiscoveryBanners
          ? resource.image || resource.game?.image || defaultBannerImageSrc
          : undefined
      }
      navigation={{
        root: RootRoute.Competition,
        rootId: resource.id
      }}
      googleTag={googleTag}
      key={resource.id}
      title={resource.name}
      subtitle={
        <Stack display='flex' direction='row' spacing={1} alignItems='center'>
          {stateIcon}
          <Box>
            {activeEvent && resource?.state === CompetitionState.INPROGRESS ? (
              <ScheduleTypography
                startDate={activeEvent?.startDate}
                endDate={activeEvent?.endDate}
                name={activeEvent?.name}
              />
            ) : (
              <RcTrans
                i18nKey={`shared.competition-state.${resource?.state}`}
              />
            )}
          </Box>
        </Stack>
      }
      primaryChips={
        resource.game
          ? [
              {
                label: resource.game?.name,
                color: 'primary',
                icon: (
                  <RcAvatar
                    variant='rounded'
                    alt={resource.game?.name}
                    src={resource.game?.icon || resource.game?.image}
                    sx={{ width: 18, height: 18 }}
                  />
                ),
                onClick: () =>
                  navTo(
                    {
                      root: RootRoute.Discovery,
                      subRoute: DiscoveryRoute.Competitions,
                      query: `tag=${resource.game?.name}`
                    },
                    true
                  ),
                sx: { cursor: 'pointer' }
              }
            ]
          : []
      }
      secondaryChips={chipinatorValues.map(it => ({
        label: it,
        color: 'primary',
        onClick: () =>
          navTo(
            {
              root: RootRoute.Discovery,
              subRoute: DiscoveryRoute.Competitions,
              query: `tag=${it}`
            },
            true
          ),
        sx: { cursor: 'pointer' }
      }))}
    />
  )
}
